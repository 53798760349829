var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "row-bg" },
    [
      _c("div", { style: { height: _vm.autoHeight + "px" } }, [
        _vm.sourceContent !== "" && _vm.sourceContent.indexOf("/") > -1
          ? _c("div", [
              _c(
                "div",
                {
                  style: { height: _vm.autoHeight + "px" },
                  attrs: { id: "waterMaskId" },
                },
                [
                  _c("iframe", {
                    staticStyle: { width: "100%", height: "100%" },
                    attrs: { src: _vm.sourceContent, allowfullscreen: "true" },
                  }),
                ]
              ),
            ])
          : _vm._e(),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value:
                  _vm.sourceContent !== "" &&
                  _vm.sourceContent.indexOf("/") == -1,
                expression:
                  "sourceContent !== '' && sourceContent.indexOf('/')==-1",
              },
            ],
            staticStyle: { width: "100%", height: "100%", overflow: "auto" },
          },
          [
            _c("LookPpt", {
              ref: "pptIframeg",
              attrs: { content: _vm.sourceContent },
              on: { changeTaskByChild: _vm.changeTaskByChild },
            }),
          ],
          1
        ),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: !_vm.sourceContent,
                expression: "!sourceContent",
              },
            ],
          },
          [
            _c(
              "div",
              {
                staticStyle: {
                  display: "flex",
                  "justify-content": "center",
                  "align-items": "center",
                  "font-size": "14px",
                },
                style: { height: _vm.autoHeight - 70 + "px" },
              },
              [_vm._v(" 暂无课件 ")]
            ),
          ]
        ),
      ]),
      _vm.showVideo
        ? _c(
            "div",
            {
              staticStyle: {
                border: "#F7CF47 1px solid",
                padding: "10px",
                background: "#FFFFFF",
                position: "absolute",
                left: "10%",
                top: "5%",
                "z-index": "1001",
                width: "85%",
                height: "85%",
              },
            },
            [
              _c(
                "div",
                {
                  staticStyle: {
                    height: "40px",
                    display: "flex",
                    "align-items": "center",
                  },
                },
                [
                  _c("span", {
                    staticClass: "el-icon-circle-close",
                    staticStyle: {
                      position: "absolute",
                      right: "20px",
                      "font-size": "24px",
                    },
                    on: {
                      click: function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "s",
                            undefined,
                            $event.key,
                            undefined
                          )
                        )
                          return null
                        $event.stopPropagation()
                        _vm.showVideo = false
                      },
                    },
                  }),
                ]
              ),
              _c("preview-video-modle", {
                attrs: {
                  sourceId: _vm.linkInfo.content,
                  type: _vm.linkInfo.type,
                  linkId: _vm.linkInfo.id,
                },
                on: {
                  sendToStudnet: _vm.sendToStudnet,
                  finishSendToStudnet: _vm.finishSendToStudnet,
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.pushType === "task" && _vm.taskInfo.type !== "课件",
            top: "3vh",
            width: "80%",
            "before-close": _vm.closeTaskPage,
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(
                _vm.pushType === "task" && _vm.taskInfo,
                "type !== '课件'",
                $event
              )
            },
          },
        },
        [
          _c(
            "div",
            { staticStyle: { margin: "15px" } },
            [
              _vm.taskInfo.type !== "课件" && _vm.taskInfo.type !== "授课资料"
                ? _c(
                    "el-button",
                    {
                      attrs: { type: "primary", size: "small" },
                      on: { click: _vm.pushTaskToStudent },
                    },
                    [_vm._v("推送任务")]
                  )
                : _vm._e(),
            ],
            1
          ),
          _c(
            "div",
            {
              staticStyle: { padding: "10px" },
              style: { height: _vm.autoHeight - 40 + "px" },
            },
            [
              _c(
                "div",
                { staticStyle: { height: "100%" } },
                [
                  _vm.showTaskFlag === 1 && _vm.currenttaskID
                    ? _c("previewTaskShow", {
                        attrs: { taskID: _vm.currenttaskID },
                        on: {
                          synchroIndex: _vm.synchroIndex,
                          asyncproblemId: _vm.asyncproblemId,
                        },
                      })
                    : _vm._e(),
                  _vm.showTaskFlag === 2 && _vm.currenttaskID
                    ? _c("pdfpreviewShow", {
                        staticStyle: { width: "100%", overflow: "scroll" },
                        style: { height: _vm.autoHeight + "px" },
                        attrs: { taskID: _vm.currenttaskID },
                      })
                    : _vm._e(),
                  _vm.showTaskFlag === 3
                    ? _c("previewVideoShow", {
                        ref: "videoref",
                        attrs: { sourceId: _vm.taskInfo.sourceId },
                      })
                    : _vm._e(),
                  _vm.showTaskFlag === 4 && _vm.currenttaskID
                    ? _c("previewPygameShow", {
                        staticStyle: {
                          height: "calc(100%)",
                          "overflow-y": "auto",
                        },
                        attrs: {
                          sourceId: _vm.taskInfo.sourceId,
                          taskInfo: _vm.taskInfo,
                        },
                      })
                    : _vm._e(),
                  _vm.showTaskFlag === 5 && _vm.currenttaskID
                    ? _c("previewScratchJrShow", {
                        staticStyle: {
                          height: "calc(100%)",
                          "overflow-y": "auto",
                        },
                        attrs: {
                          sourceId: _vm.taskInfo.sourceId,
                          taskInfo: _vm.taskInfo,
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ]
          ),
        ]
      ),
      _c(
        "div",
        {
          staticStyle: {
            position: "absolute",
            right: "5px",
            bottom: "120px",
            "z-index": "10000",
          },
        },
        [
          _c(
            "el-popover",
            {
              attrs: { placement: "left", trigger: "manual", width: "450" },
              model: {
                value: _vm.visible,
                callback: function ($$v) {
                  _vm.visible = $$v
                },
                expression: "visible",
              },
            },
            [
              _c(
                "div",
                [
                  _c(
                    "el-button",
                    {
                      attrs: { size: "small" },
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                          return _vm.preTask.apply(null, arguments)
                        },
                      },
                    },
                    [_vm._v("上一节")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { size: "small" },
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                          return _vm.nextTask.apply(null, arguments)
                        },
                      },
                    },
                    [_vm._v("下一节")]
                  ),
                  _c(
                    "el-popover",
                    {
                      staticStyle: {
                        "margin-right": "10px",
                        "margin-left": "10px",
                      },
                      attrs: {
                        placement: "top",
                        width: "400",
                        trigger: "hover",
                      },
                    },
                    [
                      _vm._l(_vm.taskListBootomArray, function (item, index) {
                        return _c("el-row", { key: index }, [
                          _c(
                            "div",
                            {
                              staticStyle: {
                                height: "30px",
                                cursor: "pointer",
                              },
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                  return _vm.changeTaskNum(index)
                                },
                              },
                            },
                            [
                              _c("span", [_vm._v("任务")]),
                              _c(
                                "span",
                                { staticStyle: { "margin-left": "5px" } },
                                [_vm._v(_vm._s(index + 1) + ":")]
                              ),
                              _c(
                                "span",
                                { staticStyle: { "margin-left": "10px" } },
                                [_vm._v(_vm._s(item.title))]
                              ),
                            ]
                          ),
                        ])
                      }),
                      _c(
                        "el-button",
                        {
                          staticClass: "el-icon-s-operation",
                          attrs: { slot: "reference", size: "small" },
                          slot: "reference",
                        },
                        [_vm._v("任务")]
                      ),
                    ],
                    2
                  ),
                  _c(
                    "el-popover",
                    {
                      staticStyle: {
                        "margin-right": "10px",
                        "margin-left": "10px",
                      },
                      attrs: {
                        placement: "top",
                        width: "400",
                        trigger: "hover",
                      },
                    },
                    [
                      _vm._l(_vm.missLessonList, function (item, index) {
                        return _c("el-row", { key: index }, [
                          _c(
                            "div",
                            {
                              staticStyle: {
                                height: "30px",
                                cursor: "pointer",
                              },
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                  return _vm.changeVideo(item)
                                },
                              },
                            },
                            [
                              _c("span", [_vm._v("视频")]),
                              _c(
                                "span",
                                { staticStyle: { "margin-left": "5px" } },
                                [_vm._v(_vm._s(index + 1) + ":")]
                              ),
                              _c(
                                "span",
                                { staticStyle: { "margin-left": "10px" } },
                                [_vm._v(_vm._s(item.title))]
                              ),
                            ]
                          ),
                        ])
                      }),
                      _c(
                        "el-button",
                        {
                          staticClass: "el-icon-video-camera",
                          attrs: { slot: "reference", size: "small" },
                          slot: "reference",
                        },
                        [_vm._v("说课视频")]
                      ),
                    ],
                    2
                  ),
                ],
                1
              ),
              !_vm.visible
                ? _c(
                    "el-button",
                    {
                      attrs: { slot: "reference", size: "mini" },
                      on: { click: _vm.govisible },
                      slot: "reference",
                    },
                    [
                      _c("i", {
                        staticClass: "el-icon-d-arrow-left",
                        staticStyle: { "font-size": "20px" },
                      }),
                    ]
                  )
                : _c(
                    "el-button",
                    {
                      attrs: { slot: "reference", size: "mini" },
                      on: { click: _vm.govisible },
                      slot: "reference",
                    },
                    [
                      _c("i", {
                        staticClass: "el-icon-d-arrow-right",
                        staticStyle: { "font-size": "20px" },
                      }),
                    ]
                  ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }