<template>
  <div class="row-bg">
    <div :style="{ height:  autoHeight +'px' }">
      <div v-if="sourceContent !== ''  && sourceContent.indexOf('/')>-1">
        <div id="waterMaskId" :style="{ height:  autoHeight+'px' }">
          <iframe
            :src="sourceContent"
            allowfullscreen="true"
            style="width: 100%;height: 100%"
          ></iframe>
        </div>
      </div>
      <div style="width:100%;height:100%;overflow:auto" v-show="sourceContent !== '' && sourceContent.indexOf('/')==-1">
        <LookPpt @changeTaskByChild="changeTaskByChild" ref='pptIframeg' :content="sourceContent"/>
      </div>
      <div v-show="!sourceContent">
        <div :style="{ height:  autoHeight - 70+'px' }"
             style="display: flex;justify-content: center;align-items: center;font-size: 14px;">
          暂无课件
        </div>
      </div>
    </div>
    <div v-if="showVideo" style="border:#F7CF47 1px solid;padding: 10px; background: #FFFFFF; position: absolute;left: 10%;top: 5%;z-index: 1001;width: 85%;height: 85%">
      <div style="height: 40px;display: flex;align-items: center;">
        <span style="position: absolute;right: 20px; font-size: 24px;" class="el-icon-circle-close" @click.s.stop="showVideo = false"></span>
      </div>
      <preview-video-modle
        :sourceId="linkInfo.content"
        :type="linkInfo.type"
        :linkId="linkInfo.id"
        @sendToStudnet="sendToStudnet"
        @finishSendToStudnet="finishSendToStudnet"
      ></preview-video-modle>
    </div>
      <el-dialog
        :visible.sync="pushType === 'task' && taskInfo.type !== '课件'"
        top="3vh"
        width="80%"
        :before-close="closeTaskPage"
      >
      <div style="margin: 15px">
        <el-button type="primary" v-if="taskInfo.type !== '课件' &&　taskInfo.type !== '授课资料'" size="small" @click="pushTaskToStudent">推送任务</el-button>
      </div>
      <div :style="{ height:  autoHeight - 40+'px' }"  style="padding: 10px">
        <div style="height: 100%;">
          <previewTaskShow
            @synchroIndex="synchroIndex"
            @asyncproblemId="asyncproblemId"
            v-if="showTaskFlag === 1 && currenttaskID"
            :taskID="currenttaskID"
          />
          <pdfpreviewShow
            v-if="showTaskFlag === 2 && currenttaskID"
            :style="{ height:  autoHeight+'px' }"
            style="width: 100%; overflow: scroll"
            :taskID="currenttaskID"
          />
          <previewVideoShow
            ref="videoref"
            v-if="showTaskFlag === 3"
            :sourceId="taskInfo.sourceId"
          />
          <previewPygameShow
            style="height: calc(100%); overflow-y: auto"
            v-if="showTaskFlag === 4 && currenttaskID"
            :sourceId="taskInfo.sourceId"
            :taskInfo="taskInfo"
          />
          <previewScratchJrShow
            style="height: calc(100%); overflow-y: auto"
            v-if="showTaskFlag === 5 && currenttaskID"
            :sourceId="taskInfo.sourceId"
            :taskInfo="taskInfo"
          />
        </div>
      </div>
      </el-dialog>
    <div style="position: absolute;right:5px;bottom: 120px;z-index: 10000">
      <el-popover
        placement="left"
        trigger="manual"
        width="450"
        v-model="visible">
        <div>
          <el-button size="small" @click.stop="preTask">上一节</el-button>
          <el-button size="small" @click.stop="nextTask">下一节</el-button>
          <el-popover
            style="margin-right: 10px;margin-left: 10px;"
            placement="top"
            width="400"
            trigger="hover">
            <el-row v-for="(item,index) in taskListBootomArray" :key='index'>
              <div style="height: 30px;cursor: pointer" @click.stop="changeTaskNum(index)">
                <span>任务</span>
                <span style="margin-left: 5px;">{{index + 1}}:</span>
                <span style="margin-left: 10px">{{item.title}}</span>
              </div>
            </el-row>
            <el-button slot="reference" class="el-icon-s-operation" size="small">任务</el-button>
          </el-popover>
          <el-popover
            style="margin-right: 10px;margin-left: 10px;"
            placement="top"
            width="400"
            trigger="hover">
            <el-row v-for="(item,index) in missLessonList" :key='index'>
              <div style="height: 30px;cursor: pointer" @click.stop="changeVideo(item)">
                <span>视频</span>
                <span style="margin-left: 5px;">{{index + 1}}:</span>
                <span style="margin-left: 10px">{{item.title}}</span>
              </div>
            </el-row>
            <el-button slot="reference" class="el-icon-video-camera" size="small">说课视频</el-button>
          </el-popover>
          <!--          <el-button size="small" :autofocus="pushType === 'video'"  @click="changeVideo">说课视频</el-button>-->
        </div>
        <el-button slot="reference" v-if="!visible" size="mini" @click="govisible">
          <i class="el-icon-d-arrow-left" style="font-size: 20px"></i>
        </el-button>
        <el-button slot="reference" v-else size="mini" @click="govisible">
          <i class="el-icon-d-arrow-right" style="font-size: 20px"></i>
        </el-button>
      </el-popover>
    </div>
  </div>
</template>

<script>
import AgoraRTM from 'agora-rtm-sdk'
import {
  courseUnitSwitch,
  getAgoraToken,
  getCourseUnitState,
  getLessonStudentInfoByIdWithPage,
  getQuestionList,
  listAttachment,
  rtmDayLog,
  saveLastPush,
  taskList
} from '@/apiteacher/teacher'

import previewTaskShow from '@/componentsTeacher/previewTask'
import pdfpreviewShow from '@/componentsTeacher/pdfpreview'
import previewVideoModle from '@/componentsTeacher/previewVideo/videoindex'
import previewVideoShow from '@/componentsTeacher/previewVideo/indexs'
import LookPpt from '@/views/PPT/look'
import previewPygameShow from "@/views/Pygame/pregive";
import previewScratchJrShow from '@/views/ScratchJr/lookd'

export default {
  name: 'giveLesson-screen',
  components: {
    previewTaskShow,
    pdfpreviewShow,
    previewVideoShow,
    previewVideoModle,
    LookPpt,
    previewPygameShow,
    previewScratchJrShow
  },
  data () {
    return {
      pythonorScratchIndex:0,
      buttonindex:0,
      visible:false,
      autoplay: false,
      missLessonList:[],
      status: '',
      currentPushTaskId: '',
      realInterrupte: false,// 5秒后如果这个值为true说明真的掉线了，自动重连没有成功
      onlineState: false,
      handfix: false,
      autoHeight: 0,
      sourceContent: '',
      showTaskFlag: 1,
      currentMenuState: null,// 'Y'标识当前课节开启了学生端目录功能，'N'标识未开启
      taskId: '',
      drawer: false,
      taskInfo: {
        title:''
      },
      lessonStudentList: [],
      lessonId: '',
      taskListArray: [],
      taskListBootomArray:[],
      currenttaskID: 0,
      questionList: [], // 记录编程列表
      problemId: '',
      originProblemId: '',
      globalClient: null,
      globalChannel: null,
      videoEleId: '#player1',
      pushType:'',
      currentIndex:0,
      courseUnitId:'',
      currentProblemId:'0',
      pushFlag:-1,

      linkInfo:null,
      showVideo:false,
    }
  },
  watch: {
    $route: {
      immediate: true,
      handler () {
        this.pushFlag = this.getUrlParam('pushFlag', window.location.href)
        if(this.pushFlag === '1') {
          this.lessonId = this.getUrlParam('lessonId', window.location.href)
          this.pushType = this.getUrlParam('pushType', window.location.href)
          this.courseUnitId = this.getUrlParam('courseUnitId', window.location.href)
          this.currentIndex = this.getUrlParam('currentIndex', window.location.href)
          this.autoHeight = document.documentElement.clientHeight
          if(this.taskListArray.length === 0) {
            this.tasLeftkList()
          }else {
            if(this.pushType === 'courseWare') {
              this.changeTask()
            }else if(this.pushType === 'task') {
              this.taskInfo = this.taskListArray[this.currentIndex]
              this.currenttaskID = this.taskInfo.id
            }
          }
        }
      }
    },
  },
  mounted () {
    this.$nextTick(()=> {
      this.visible = true
    })
    this.getTmsToken()
    this.getLessonStudentInfoByIdWithPage()
    this.pushFlag = this.getUrlParam('pushFlag', window.location.href)
    this.lessonId = this.getUrlParam('lessonId', window.location.href)
    this.pushType = this.getUrlParam('pushType', window.location.href)
    this.courseUnitId = this.getUrlParam('courseUnitId', window.location.href)
    this.currentIndex = this.getUrlParam('currentIndex', window.location.href)
    this.autoHeight = document.documentElement.clientHeight
    if(this.pushFlag === '1') {
      if(this.taskListArray.length === 0) {
        this.tasLeftkList()
      }else {
        if(this.pushType === 'courseWare') {
          this.changeTask()
        }else if(this.pushType === 'task') {
          this.taskInfo = this.taskListArray[this.currentIndex]
          this.currenttaskID = this.taskInfo.id
        }
      }
    }else {
      if(this.pushType === 'courseWare') {
        this.changeTask()
      }else if(this.pushType === 'task') {
        this.taskInfo = this.taskListArray[this.currentIndex]
        this.currenttaskID = this.taskInfo.id
      }
    }
    window.addEventListener('beforeunload', e => _this.beforeunloadHandler(e))
    window.addEventListener('unload', e => _this.unloadHandler(e))

  },
  destroyed () {
    window.removeEventListener('beforeunload', e => this.beforeunloadHandler(e))
    window.removeEventListener('unload', e => this.unloadHandler(e))
  },
  methods: {
    finishSendToStudnet(){
      this.sendNotasktoStudent()
    },
    sendToStudnet(data) {
      this.taskListArray.forEach(item => {
        if(item.id === data) {
          this.taskInfo = item
          this.currenttaskID = item.id
        }
      })
      if (this.taskInfo.learnPurpose !== 'Y') {
        window.$msgnew('此任务不支持推送给学员', 2,'zZindex')
        return false
      } else if (this.handfix || this.onlineState === false) {
        window.$msgnew('你已经从互动课堂掉线，请尝试刷新页面或者手动修复', 1,'zZindex')
        return false
      }
      this.sendTask()
    },
    sendNotasktoStudent () {
      let flag = this.lessonStudentList.find((item) => {
        return item.isLogin === '是'
      })
      if (flag === undefined) {
        window.$msg('暂时没有学员在线', 2)
        return false
      }

      let textObject = {
        sendType: 'gobackPlatform',
      }
      this.originProblemId = this.problemId.toString()
      this.globalChannel
        .sendMessage({ text: JSON.stringify(textObject) })
        .then(() => {
          /* 频道消息发送成功的处理逻辑 */

          window.$msg('结束任务成功')
          this.saveLastPush(JSON.stringify(textObject))
          this.rtmDayLog('allPush', '结束任务成功+taskId-----gobackPlatform')
        })
        .catch((error) => {
          console.log('sendMesssage=======>fail')
          this.rtmDayLog('allPush', '结束任务失败+sendMesssage=======>fail' + error)
          /* 频道消息发送失败的处理逻辑 */
        })
    },
    closeTaskPage() {
      this.pushType = 'courseWare'
      if (this.taskInfo.type === '视频') {
        this.$refs.videoref.player.j2s_pauseVideo()
      }
      // this.$router.push({
      //   path: this.$route.path,
      //   query: {
      //     lessonId:this.lessonId,
      //     courseUnitId:this.courseUnitId,
      //     pushType:'courseWare',
      //     pushFlag:1,
      //     currentIndex:this.currentIndex
      //   }
      // })
    },
    asyncproblemId(data) {
      this.currentProblemId = data.problemId
    },
    synchroIndex(data) {
      this.pythonorScratchIndex = data.index
    },
    async saveLastPush (content) {
      await saveLastPush({lessonId: this.lessonId,content:content})
    },
    changeTaskNum(index) {
      this.showVideo = false
      this.taskInfo = this.taskListBootomArray[index]
      this.currenttaskID = this.taskInfo.id
      this.pushType = 'task'
      switch (this.taskInfo.type) {
        case 'pdf':
          this.showTaskFlag = 2
          break
        case '视频':
          this.showTaskFlag = 3
          break;
        case "Pygame":
          this.showTaskFlag = 4;
          break;
        case "scratchjr":
          this.showTaskFlag = 5;
          break;
        default:
          this.showTaskFlag = 1
      }
    },
    changeVideo(item) {
      this.showVideo = true
      this.linkInfo = item
    },
    changeTaskByChild(taskID){
      let item,index;
      this.taskListArray.forEach((i,j)=>{
        if(i.id === taskID ){
          item = i;
          index =j;
        }
      })
      if(item){
        this.taskInfo.id = taskID
        this.changeTask();
      }
    },
    preTask() {
      if (this.currentIndex < 1) {
        this.currentIndex = 0
        window.$msgnew('已经到第一个任务了',2,'zZindex')
        return false
      }else {
        this.currentIndex --;
        this.changeTask()
        this.$router.push({
          path: this.$route.path,
          query: {
            lessonId:this.lessonId,
            courseUnitId:this.courseUnitId,
            pushType:'courseWare',
            pushFlag:1,
            currentIndex:this.currentIndex
          }
        })
      }
    },
    nextTask() {
      if (this.currentIndex > this.taskListArray.length - 2) {
        this.currentIndex = this.taskListArray.length - 1
        window.$msg('已经到最后一个任务了',2)
        return false
      }else {
        this.currentIndex ++;
        this.changeTask()
        this.$router.push({
          path: this.$route.path,
          query: {
            lessonId:this.lessonId,
            courseUnitId:this.courseUnitId,
            pushType:'courseWare',
            pushFlag:1,
            currentIndex:this.currentIndex
          }
        })
      }
    },
    govisible(){
      this.visible = !this.visible
    },
    beforeunloadHandler () {
      this._beforeUnload_time = new Date().getTime()
    },
    unloadHandler (e) {
      this._gap_time = new Date().getTime() - this._beforeUnload_time
      //判断是窗口关闭还是刷新
      if (this._gap_time <= 5) {
        //关闭当前页面
        this.$watermark.set('')
        if (this.globalChannel !== null) {
          this.globalChannel.leave()
        }
        if (this.globalClient !== null) {
          this.globalClient.logout()
        }
        this.rtmDayLog('老师下线', '老师关闭浏览器')
      }
    },
    pushTaskToStudentBefore (item) {
      this.problemId = item.problem_id
      this.$nextTick((res) => {
        this.pushTaskToStudent()
      })
    },
    //声网操作开始
    pushTaskToStudent () {
      console.log(this.taskInfo)
      if (this.taskInfo.learnPurpose !== 'Y') {
        window.$msgnew('此任务不支持推送给学员', 2,'zZindex')
        return false
      } else if (this.handfix || this.onlineState === false) {
        window.$msgnew('你已经从互动课堂掉线，请尝试刷新页面或者手动修复', 1,'zZindex')
        return false
      }
      this.sendTask()
    },

    sendTask () {
      let flag = this.lessonStudentList.find((item) => {
        return item.isLogin === '是'
      })
      if (flag === undefined) {
        window.$msgnew('暂时没有学员在线', 2,'zZindex')
        return false
      }
      this.currentPushTaskId = this.currenttaskID
      let textObject = {
        taskId: this.currenttaskID,
        questionId: this.currentProblemId,
        index:this.pythonorScratchIndex,
        sendType: 'allPush',
      }
      this.originProblemId = this.problemId.toString()
      this.globalChannel
        .sendMessage({ text: JSON.stringify(textObject) })
        .then(() => {
          /* 频道消息发送成功的处理逻辑 */
          window.$msgnew('推送任务成功',0,'zZindex')
          this.saveLastPush(JSON.stringify(textObject))
          this.rtmDayLog('allPush', '推送任务成功+taskId-----' + this.currenttaskID)
        })
        .catch((error) => {
          console.log('sendMesssage=======>fail')
          this.rtmDayLog('allPush', '推送任务失败+sendMesssage=======>fail' + error)
          /* 频道消息发送失败的处理逻辑 */
        })
    },
    async getTmsToken () {
      const response = await getAgoraToken({
        loginName: localStorage.getItem('userName') + '_screen',
      })
      if (response.body.appid === null || response.body.rtmToken === null) {
        window.$msg('token值为空，不能登录直播间，请联系管理员', 2)
        return false
      } else if (response.state === 'success') {
        this.onlineState = true
      }

      this.initAgora(response.body.rtmToken, response.body.appId)
    },

    initAgora (token, appid) {
      let that = this
      this.globalClient = AgoraRTM.createInstance(appid)

      this.globalClient.on('ConnectionStateChanged', (newState, reason) => {
        if (reason === 'INTERRUPTED') { //声网sdk和服务器断开连接
          this.realInterrupte = true
          setTimeout(() => {
            if (this.realInterrupte) {
              this.onlineState = false
              this.handfix = true
              window.$msgnew('你已经从互动课堂离线，请检查你的网络连接', 1,'zZindex')
              this.rtmDayLog('INTERRUPTED', '老师掉线并且5秒之内没有连接上')
            }
          }, 5000)
        } else if (reason === 'LOGIN_SUCCESS') {
          this.realInterrupte = false
          this.onlineState = true
          this.handfix = false
          this.rtmDayLog('LOGIN_SUCCESS', '老师成功登录到rtm系统')
        } else if (reason === 'REMOTE_LOGIN') {
          this.onlineState = false
          this.handfix = true
          this.rtmDayLog('REMOTE_LOGIN', '学生被踢退或者token过期')
        } else if (reason === 'LOGIN_FAILURE') {
          this.onlineState = false
          this.handfix = true
          this.rtmDayLog('LOGIN_FAILURE', 'SDK 登录 Agora RTM 系统失败。')
        }
        console.log(
          'on connection state changed to ' + newState + ' reason: ' + reason
        )
      })
      this.globalClient
        .login({ token: token, uid: localStorage.getItem('userName') + '_screen' })
        .then(() => {
          // console.log('login success')
          this.rtmDayLog('RTM初始化成功', 'RTM初始化成功')
          this.globalChannel = this.globalClient.createChannel(that.lessonId) // 此处传入频道 ID
          this.globalChannel
            .join()
            .then(() => {
              this.globalChannel.getMembers().then((members) => {
                /*获取此频道内的所有成员的uid*/
                // console.log(members)
                this.rtmDayLog('老师加入频道', '老师加入频道成功')
                members.forEach((item) => {
                  let student = that.lessonStudentList.find((value) => {
                    // console.log(value.loginName + '------------' + item)
                    return value.loginName === item
                  })
                  if (student !== undefined) {
                    student.isLogin = '是'
                  }
                })
                that.$forceUpdate()
              })
            })
            .catch((error) => {
              /* 加入频道失败的处理逻辑 */
            })

          this.globalChannel.on('MemberJoined', (memberId) => {
            // text 为收到的频道消息文本，senderId 为发送方的 User ID
            /* 此频道内有新的成员加入回调 */
            // console.log(memberId + '====加入了频道')
            let student = that.lessonStudentList.find((value) => {
              return value.loginName === memberId
            })
            student.isAttend = '是'
            student.isLogin = '是'
            that.$forceUpdate()
          })
          this.globalChannel.on('MemberLeft', (memberId) => {
            // text 为收到的频道消息文本，senderId 为发送方的 User ID
            /* 此频道内有成员退出回调 */
            // console.log(memberId + '====离开了频道')
            let student = that.lessonStudentList.find((value) => {
              return value.loginName === memberId
            })
            student.isLogin = '否'
            that.$forceUpdate()
          })
        })
        .catch((err) => {
          this.rtmDayLog('RTM初始化失败', 'RTM初始化失败login failure' + err)
          console.log('AgoraRTM client login failure', err)
        })
    },
    //保存日志操作
    async rtmDayLog (eventCode, desp) {
      rtmDayLog({
        lessonId: this.lessonId,
        source: '教师端',
        eventCode: eventCode,
        desp: desp
      })
    },

    changeTask () {

      if(this.$refs.pptIframeg && this.$refs.pptIframeg.changeSlidesPage){
        this.$refs.pptIframeg.changeSlidesPage(this.taskInfo.id)
      }

      if (this.taskInfo.type == '编程') {
        getQuestionList({ contest_id: this.taskInfo.sourceId }).then((res) => {
          // console.log(res);
          if (res.state == 'success') {
            this.questionList = res.body
          }
        })
      } else {
        this.questionList = []
      }
      if(this.pushType === 'courseWare') {
        this.listAttachmentCourseWare(this.taskListArray[this.currentIndex].id)
      }
      this.listAttachmentVideo(this.taskListArray[this.currentIndex].id)
      switch (this.taskInfo.type) {
        case 'pdf':
          this.showTaskFlag = 2
          break
        case '视频':
          this.showTaskFlag = 3
          break
        case 'Pygame':
          this.showTaskFlag = 4
          break
        case 'scratchjr':
          this.showTaskFlag = 5
          break
        default:
          this.showTaskFlag = 1
      }
    },
    async tasLeftkList () {
      const res = await taskList({
        courseUnitId: this.courseUnitId,
      })
      this.taskListArray = res.body

      this.taskListBootomArray = []
      res.body.forEach((item,index) => {
        if(item.type !== '课件'　&& item.type !== '授课资料') {
          this.taskListBootomArray.push(item)
        }
      })
      if (this.taskListArray.length !== 0) {
        this.taskInfo = this.taskListArray[this.currentIndex]
        if (this.taskInfo.type == '编程') {
          getQuestionList({ contest_id: this.taskInfo.sourceId }).then((res) => {
            // console.log(res);
            if (res.state == 'success') {
              this.questionList = res.body
            }
          })
        } else {
          this.questionList = []
        }
        this.currenttaskID = this.taskInfo.id
        // this.listAttachmentVideo(this.taskInfo.id)
        if(this.pushType === 'courseWare') {
          this.listAttachmentCourseWare(this.taskInfo.id)
        }
        this.listAttachmentVideo(this.taskInfo.id)
        switch (this.taskInfo.type) {
          case 'pdf':
            this.showTaskFlag = 2
            break
          case '视频':
            this.showTaskFlag = 3
            break;
          case "Pygame":
            this.showTaskFlag = 4;
            break;
          case "scratchjr":
            this.showTaskFlag = 5;
            break;
          default:
            this.showTaskFlag = 1
        }
      }
    },

    // async listAttachmentVideo (taskID) {
    //   const res = await listAttachment({
    //     taskId: taskID,
    //     type: '补课视频',
    //   })
    //   // this.optionsVideo = res.body
    //   if (res.body.length !== 0) {
    //     this.status = res.body[0].content
    //     const params = {
    //       sourceVideoId: res.body[0].content,
    //     }
    //     getVideoToken(params).then((res) => {
    //       if (res.state == 'success') {
    //         this.vidInfo = JSON.parse(res.body.playToken)
    //         this.$nextTick(() => {
    //           if (this.vidInfo.data && this.vidInfo.data.token) {
    //             this.loadPlayerScript(this.loadPlayer)
    //           }
    //         })
    //       }
    //     })
    //   } else {
    //     this.status = ''
    //   }
    // },
    async listAttachmentVideo (taskID) {
      console.log('ssssssssssssss')
      const res = await listAttachment({
        taskId: taskID,
        type: '补课视频',
      })
      if (res.body && res.state === 'success') {
        this.missLessonList = res.body
      }
    },
    async listAttachmentCourseWare (taskID) {
      console.log('currentIndex------------'+this.currentIndex)
      const res = await listAttachment({
        taskId: taskID,
        type: '课件',
      })
      if (res.body.length !== 0) {
        if(res.body[0].onlinePpt=='Y'){
          this.onlinePpt = 'Y';
          this.sourceContent = res.body[0].content;
        }else if(res.body[0].onlinePpt=='N' && res.body[0].content.indexOf('/')>=0){
          this.sourceContent =
            process.env.VUE_APP_PREVIEW_ADDRESS + res.body[0].content;
        }else{
          this.sourceContent = ""
        }
      } else {
        this.sourceContent = ""
      }
    },
    loadPlayerScript (callback) {
      if (!window.polyvPlayer) {
        const myScript = document.createElement('script')
        myScript.setAttribute('src', this.vodPlayerJs)
        myScript.onload = callback
        document.body.appendChild(myScript)
      } else {
        callback()
      }
    },
    loadPlayer () {
      this.destroyed()
      this.player = null
      const polyvPlayer = window.polyvPlayer
      this.player = polyvPlayer({
        wrap: this.videoEleId,
        // width: 800,
        height: this.autoHeight - 90,
        vid: this.vidInfo.data.videoId,
        playsafe: this.vidInfo.data.token,
        code:'可达鸭编程—'+localStorage.getItem('userName')+'|'+localStorage.getItem('token_business')
      })
    },
    destroyed () {
      if (this.player) {
        this.player.destroy()
      }
    },
    //操作打开或者关闭学生端目录的二级提示框
    openStudentTask () {
      this.$confirm(this.currentMenuState === 'Y' ? '确定要关闭学生端目录功能吗？' : '确定要打开学生端目录功能吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        closeOnClickModal: false,
        closeOnPressEscape: false,
      }).then(() => {
        this.courseUnitSwitch()
        this.rtmDayLog('老师打开学生端目录', '老师手动打开学生端目录功能')
      }).catch(() => {

      })
    },
    //获取当前排课的学生端目录是否开启
    async getCourseUnitState () {
      const res = await getCourseUnitState({
        lessonId: this.lessonId,
      })
      if (res.state === 'success') {
        this.currentMenuState = res.body
      }
    },
    //打开学生端的目录状态
    async courseUnitSwitch () {
      const res = await courseUnitSwitch([{
        lessonId: this.lessonId,
        state: this.currentMenuState === null ? 'Y' : this.currentMenuState === 'N' ? 'Y' : 'N'
      }])
      if (res.state === 'success') {
        if (this.currentMenuState === 'Y') {
          this.currentMenuState = 'N'
          window.$msg('已关闭学生端目录功能，请通知学生刷新学生端页面',)
        } else if (this.currentMenuState === 'N' || this.currentMenuState === null) {
          this.currentMenuState = 'Y'
          window.$msg('已开启学生端目录功能，请通知学生刷新学生端页面')
        }
        this.$forceUpdate()
      }
    },
    //查询学生在互动课堂的在线状态以及签到情况
    async getLessonStudentInfoByIdWithPage () {
      const response = await getLessonStudentInfoByIdWithPage({
        lessonId: this.lessonId,
        pageNum: 1,
        pageSize: 10000,
        needCount: true,
      })
      this.lessonStudentList = response.body.list
    },
    getUrlParam (name, urlsearch) {
      return (
        decodeURIComponent(
          (new RegExp('[?|&]' + name + '=' + '([^&;]+?)(&|#|;|$)').exec(
            urlsearch || location.search
          ) || [, ''])[1].replace(/\+/g, '%20')
        ) || null
      )
    },
  },
}
</script>

<style lang="scss" scoped>
@import "../../style/container.scss";

.emptyVideo {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  font-size: 14px;
  margin-bottom: 40px;
}

.studentitem {
  font-size: 14px;
  line-height: 30px;
}

.jiaoancontent {
  margin-left: 20px;
  font-size: 12px;
}

.jiaoantitle {
  font-size: 12px;
  font-weight: 600;
}

/deep/ .jiaoan {
  padding: 10px;
  margin-top: 20px;
  margin-bottom: 100px;
}

/deep/ .parentScroll {
  overflow: scroll;
}

/deep/ .titleName {
  font-size: 16px;
  font-weight: 600;
}

.headertop {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

/deep/ .taskitem {
  font-size: 14px;
  line-height: 40px;
}

/deep/ .line {
  background: #cecece;
  width: 100%;
  height: 1px;
  margin-top: 10px;
}

/deep/ .customBut {
  text-align: center;
  background: #f9f0ff;
  color: #722ed1;
  padding: 3px 10px;
  border-radius: 5px;
  font-size: 12px;
}

/deep/ .customButone {
  text-align: center;
  background: #fff2e8;
  color: #fa541c;
  margin-left: 5px;
  padding: 3px 10px;
  border-radius: 5px;
  font-size: 12px;
}

/deep/ .customButtwo {
  text-align: center;
  background: #f6ffed;
  color: #52c41a;
  margin-left: 5px;
  padding: 3px 10px;
  border-radius: 5px;
  font-size: 12px;
}

/deep/ .span-stategreen {
  display: inline-block;
  position: relative;
  top: -1px;
  width: 6px;
  height: 6px;
  background-color: #4DA54D;
  vertical-align: middle;
  border-radius: 50%;
  margin-right: 8px;
}

/deep/ .span-statered {
  display: inline-block;
  position: relative;
  top: -1px;
  width: 6px;
  height: 6px;
  background-color: #CE433F;
  vertical-align: middle;
  border-radius: 50%;
  margin-right: 8px;
}

/deep/ .el-dialog__body {
  padding: 10px 0 0 0;
}

/deep/ .container {
  height: 100%;
}

/deep/ .el-main {
  padding: 0px !important;
}

/deep/ .el-main::-webkit-scrollbar {
  display: none;
}
.el-carousel__item span {
  width: 100%;
  display: flex;
  justify-content: center;
  color: #000000;
  font-size: 14px;
  padding-bottom: 5px;
  line-height: 45px;
  margin: 0;
}

.el-carousel__item:nth-child(n) {
  background-color: #99a9bf;
}

/deep/ .el-carousel__indicator--horizontal {
  display: none;
}
/deep/ .el-tabs__nav-scroll {
  display: none;
}
/deep/ .el-button--mini {
  padding: 30px 2px;
}
.row-bg {
  padding: 0 $px12 0 $px12 !important;
}
</style>

<style>
.zZindex {
  z-index:30000 !important;
}
</style>
